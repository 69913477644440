/*Define Variables for color of the site*/
:root {
  --main-background-color: #237cfe;
  --secondary-main-color: #8ea8bf;
  --dark-gray: #787a91;
  --gray: #eeeeee;
  --white: #ffffff;
  --light-red: #f65a83;
  --red-danger: #ffb3b3;
  --light-green: #59ce8f;
  --green: #3ccf4e;
  --orange: #ffb319;
  --balck: #000000;
}

html {
  position: relative;
  min-height: 100%;
}
body {
  font-family: "Noto Sans", sans-serif;
}

.menu-card {
  border: 1px solid var(--dark-gray);
  border-radius: 5px;
  background-color: var(--main-background-color);
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.menu-card:hover {
  background-color: var(--dark-gray);
  border-radius: 5px;
  border: 1px solid var(--dark-gray);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  line-height: 60px; /* Vertically center the text there */
  background-color: var(--main-background-color);
  padding-bottom: 0px;
}
.navbar {
  padding: 0px;
  justify-content: start;
}

.navbar a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  text-decoration: none;
  font-size: 17px;
  padding: 14px 20px;
}

/* Change the color of links on hover */
.navbar a:hover {
  background-color: #ddd;
  color: black;
}

/* Add a color to the active/current link */
.navbar a.active {
  background-color: #04aa6d;
  color: white;
}
.img-microsoft {
  width: 400px;
  cursor: pointer;
}

.container-login {
  background-color: var(--main-background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.link {
  text-decoration: none;
  color: black;
}

.margin-container-bottom {
  margin-bottom: 200px;
}

.container-zone-buttons{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
}


  .container-route-buttons{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .containter-route-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }



/* media query for mobile */
@media (max-width: 768px) {
  .menu-card {
    width: calc(100vw - 20px);
  }
  .img-microsoft {
    width: 300px;
  }

  .container-zone-buttons{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }



}
